.content-container{
    background-color: #fff;
    margin-bottom: 20px;
}

.content-header{
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: row;
    padding: 15px;
    border-bottom: 1px solid #ddd;
}

.content-header div{
    width: 50%;
}

.content-main{
    padding: 15px;
}


.btn:hover{
    transform: scale(1.05);
    cursor: pointer;
}

.btn-primary{
    background-color: #0984e3;
}

.btn-danger{
    background-color: #d63031;
}

/* Table */

#customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

#customers td,
#customers th {
    border: 1px solid #ddd;
    padding: 8px;
}

#customers tr:nth-child(even) {
    background-color: #f2f2f2;
}

#customers tr:hover {
    background-color: #ddd;
}

#customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04aa6d;
    color: white;
}

.mdb-dataTable-head{
    background-color: #04aa6d !important;
    color: #fff;
}

.mdb-datatable-filter{
    margin-bottom: 20px;
}

.input-edit{
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ddd
}
