#root:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background-image: url('https://mmc.tirto.id/image/2018/11/20/sejarah-nama-intelkam--ilustrasi--nadya-02.jpg');
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
  }
  
  #root {
    position: relative;
  }




@media screen and (min-width: 1024px){
    .container{
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    
    .card{
        width: 40%;
        height: auto;
        padding: 20px;
    }
    
    .form{
        display: flex;
        flex-direction: column;
    }
    
    .logo{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
       
    }
    
    .logo img{
        /* width: 50%; */
        margin-bottom: 20px;
    }
}

@media screen and (min-width: 481px) and (max-width: 1024px){
    .container{
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    
    
    .card{
        width: 60%;
        height: auto;
        padding: 20px;
    }
    
    .form{
        display: flex;
        flex-direction: column;
    }
    
    .logo{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
       
    }
    
    .logo img{
        /* width: 50%; */
        margin-bottom: 20px;
    }
}

@media (max-width: 480px){
    .container{
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    
    .card{
        width: 100%;
        height: auto;
        padding: 20px;
    }
    
    .form{
        display: flex;
        flex-direction: column;
    }
    
    .logo{
        /* padding: 20px; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
       
    }
    
    .logo img{
        width: 50%;
        margin-bottom: 20px;
    }
}